import { getLocalStorage } from "../utils"

const parseMessages = (messages) => {
    const messagestoSend = messages.slice(1, messages.length).filter(x => x.title !== "");
    
    return messagestoSend.map((m) => {
        return {
            "role": m.position === "left" ? "assistant" : "user",
            "content": m.text
        }
    })
}

export const sendMessage = (messages) =>{
    const body = {
        "messages": parseMessages(messages)
    }

    const team = JSON.parse(getLocalStorage("team", "{}"));

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'teamName': team.team
        },
        body: JSON.stringify(body),
    };

    return fetch('api/ChatPDF/sendMessage', options)
        .then(response => response.json())
        .then(json => {
            return json;
        })  
        .catch(err => console.log('Solicitud fallida', err));
}