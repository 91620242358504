import React from 'react';
import { Col, Row } from "reactstrap"

export const TeamPlayersStatsTable = ({ teamPlayersStats, isRFEP }) => {

    const getTeamPlayersStatsRow = () => {
        return teamPlayersStats.map((team, i) => {
            const rowClassName = i % 2 === 0 ? "row-odd" : "row-even";
            return (
                <Row className={rowClassName} key={team.name}>
                    <Col xs="4" style={{ textAlign: 'left' }}>{team.name}</Col>
                    <Col xs="8">
                        <Row>
                            <Col xs="3" style={{ textAlign: 'right' }}>{team.matches}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{team.shoots}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{team.goals}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(team.shootsXGoal).toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col xs="3" style={{ textAlign: 'right' }}><img src={team.teamLogoLink} width="30px" height="30px" /></Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(team.shootsPerMatch).toFixed(2)}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(team.goalsPerMatch).toFixed(2)}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(team.goalsAVG).toFixed(2)}</Col>
                        </Row></Col>
                </Row>
               )
        })
    }

    return (<>
        <Row className="stats-header-row">
            <Col xs="4" style={{ textAlign: 'left' }}>Equipo</Col>
            <Col xs="8">
                <Row>
                    <Col xs="3" style={{ textAlign: 'right' }}>PJ</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>Tiros</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>Goles</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>TxG</Col>
                </Row>
                <Row>
                    <Col xs="3" style={{ textAlign: 'right' }}></Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>TxP</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>GxP</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>%</Col>
                </Row>
            </Col>
        </Row>
        {getTeamPlayersStatsRow()}
    </>


    )
}
