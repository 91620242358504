import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Breadcrumb, BreadcrumbItem } from "reactstrap"
import { Loading } from "../../components/Loading"
import { CalendarTable } from '../../components/Calendar/CalendarTable';
import { ClassificationTable } from '../../components/Classification/ClassificationTable';
import { PlayerStatsTable } from '../../components/PlayerStats/PlayerStatsTable';
import { GoalKeeperStatsTable } from '../../components/PlayerStats/GoalKeeperStatsTable';
import { TeamPlayersStatsTable } from '../../components/PlayerStats/TeamPlayersStatsTable';
import { getLocalStorage, setLocalStorage } from "../../utils"
import { getFireConfig } from "../../services/firebase"
import { Helmet } from "react-helmet"
import './Division.css';

export class Division extends Component {
    static displayName = Division.name;
    constructor(props) {
        super(props);
        let lastActiveTab = getLocalStorage("division-tab", "1");
        this.state = { division: {}, loading: true, activeTab: lastActiveTab, isGamePageActive: false };
    }

    componentDidMount() {
        const { match } = this.props;
        const divisionId = match.params.id;
        this.setState({ division: { divisionId } })
        this.populateDivisionsData(divisionId);
        getFireConfig("isGamePageActive").then((res) => {
            this.setState({
                ...this.state, isGamePageActive: res !== "false"
            })
        })
    }

    componentWillUnmount() {
    }


    static renderDivisionTable(division, isLoading, that) {
        const onChangeTab = (newTab) =>
        {
            that.setState({ ...that.state, activeTab: newTab })
            setLocalStorage("division-tab", newTab)
        }

        if (isLoading) {
            return null;
        }

        let divs = division.classificationList;

        return (<>
            <Container className="division-container">
                <Nav pills fill>
                    <NavItem>
                        <NavLink
                            className={that.state.activeTab === "1" ? "active" : ""}
                            onClick={() => onChangeTab("1")}
                        >
                            Calendario
                        </NavLink>
                    </NavItem>
                    {division.playerStats.length > 0 && (<NavItem>
                        <NavLink
                            className={that.state.activeTab === "2" ? "active" : ""}
                            onClick={() => onChangeTab("2")}
                        >
                            Plantilla
                        </NavLink>
                    </NavItem>)}
                    {divs.length > 0 && (<NavItem>
                        <NavLink
                            className={that.state.activeTab === "3" ? "active" : ""}
                            onClick={() => onChangeTab("3")}
                        >
                            Clasificación
                        </NavLink>
                    </NavItem>)}
                    {division.goalKeepersStats.length > 0 && (<NavItem>
                        <NavLink
                            className={that.state.activeTab === "4" ? "active" : ""}
                            onClick={() => onChangeTab("4")}
                        >
                            Porteros
                        </NavLink>
                    </NavItem>)}
                    {division.teamPlayersStats.length > 0 && (<NavItem>
                        <NavLink
                            className={that.state.activeTab === "5" ? "active" : ""}
                            onClick={() => onChangeTab("5")}
                        >
                            Tiros / Goles
                        </NavLink>
                    </NavItem>)}
                </Nav>
                <TabContent activeTab={that.state.activeTab} className="division-tab-content">
                    <TabPane tabId="1">
                        {division.calendars.map(div => {
                            return (<div key={ div.title } className="division-section">
                                <h3>{ div.title }</h3>
                                <CalendarTable calendar={div.calendars} />
                            </div>)
                        })}
                    </TabPane>

                    {division.playerStats.length > 0 && (<TabPane tabId="2">
                        <PlayerStatsTable playerStats={division.playerStats} isRFEP={division.isRfep} />
                    </TabPane>)}

                    {divs.length > 0 && (<TabPane tabId="3">
                        {divs.map((div, divIndex) => {
                            return <ClassificationTable division={div} index={divIndex} isRfep={division.isRfep} />
                        })}
                    </TabPane>)}

                    {division.goalKeepersStats.length > 0 && (<TabPane tabId="4">
                        <GoalKeeperStatsTable goalKeepersStats={division.goalKeepersStats} isRFEP={division.isRfep} />
                    </TabPane>)}

                    {division.teamPlayersStats.length > 0 && (<TabPane tabId="5">
                        <TeamPlayersStatsTable teamPlayersStats={division.teamPlayersStats} isRFEP={division.isRfep} />
                    </TabPane>)}
                    
                </TabContent>
            </Container>
        </>
        );
    }

    render() {
        let contents = Division.renderDivisionTable(this.state.division, this.state.loading, this);
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const isTherePlayerStats = this.state.division?.playerStats?.length > 0

        return (
            <>
                {this.state.division && (<Helmet>
                    <title>{`${this.state.division?.name} Hockey línea Comunidad Valenciana`}</title>
                    <meta name="description" content={`Clasificación, calendario, plantilla y estadísticas del club ${team?.name ?? "Llops Vila-real"} en ${this.state.division?.name} hockey línea`} />
                    <meta name="keywords" content={`clasificación ${this.state.division?.name} hockey línea, resultados ${this.state.division?.name} hockey línea`} />
                    <meta name="robots" content="index, follow" />
                </Helmet>)}
                <div>
                    <Row>
                        <Col xs="9">
                            <Breadcrumb>
                                <BreadcrumbItem active onClick={() => this.props.history.goBack()}>Atrás</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        {this.state.isGamePageActive && isTherePlayerStats && (<Col xs="3">
                            <Link to={`/game/${this.state.division?.divisionId}`} className="nav-link">Tu Línea</Link>
                        </Col>)}
                    </Row>
                    <h1 id="tabelLabel">{this.state.division?.name}</h1>
                    <p>{this.state.loading ? (
                        <Loading />
                    ) : null}</p>
                    {contents}
                </div>
                </>
            
        );
    }

    async populateDivisionsData(divisionId) {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch(`api/division/${divisionId}`, { headers: { "teamName": team.team } });
        const data = await response.json();
        this.setState({ division: data, loading: false });
    }
}
