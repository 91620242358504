import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import { getGravatarImage } from "../../utils"
import './Avatar.css'
import 'react-loading-skeleton/dist/skeleton.css'

export function Avatar({ player, onClickPlayer, size = "250px" }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const image = player.imageUrl && player.imageUrl.length > 0 && player.age >= 12 ? player.imageUrl : getGravatarImage(player.name)

    const getHeigth = () => {
        const numberPixels = parseInt(size.replace("px", ""))
        return numberPixels * 1.33;
    }
    return (
        <div className="avatar-item" onClick={() => { onClickPlayer && onClickPlayer(player) }}>
            {!imageLoaded && (<Skeleton width={size} height={size} />)}
            <img width={size} height={getHeigth()} onLoad={() => setImageLoaded(true)} src={image} className={imageLoaded ? "avatar-image" : "avatar-image-hidden"} alt={player.name} />
            { player.name }
        </div>
    );
}
