import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { PenaltySquare } from './PenaltySquare';

export class PenaltiesScorer extends Component {
    static displayName = PenaltiesScorer.name;
    render() {
        const { match } = this.props;
        const getPenaltyRow = (penalty) => {
            return (<Row key={`${penalty.numberHome}-${penalty.numberAway}-${penalty.goalHome}`}>
                <Col xs="1">{penalty.numberHome}</Col>
                <Col xs="3">{penalty.nameHome}</Col>
                <Col xs="2" style={{ textAlign: 'right' }}><PenaltySquare score={penalty.goalHome} /></Col>
                <Col xs="2"><PenaltySquare score={penalty.goalAway} /></Col>
                <Col xs="3">{penalty.nameAway}</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>{penalty.numberAway}</Col>
            </Row>)
        }

        return (
            <>
                <Row>
                    <Col style={{ textAlign: 'center' }}><img src={match.logo1} width="35px" height="35px" /></Col>
                    <Col style={{ textAlign: 'center' }}><img src={match.logo2} width="35px" height="35px" /></Col>
                </Row>
                {match.penalties.map((p) => {
                    return getPenaltyRow(p);
                })}
            </>
        );
    }
}
