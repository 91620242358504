import React from 'react';
import { Col, Row } from "reactstrap"
//import { Link } from 'react-router-dom';

export const GoalKeeperStatsTable = ({ goalKeepersStats, isRFEP }) => {

    const getGoalKeeperStatsRow = () => {
        return goalKeepersStats.map((player, i) => {
            const rowClassName = i % 2 === 0 ? "row-odd" : "row-even";
            //const playerLink = `/player/${player.playerId}?isRFEP=${isRFEP}`;
            return (
                <Row className={rowClassName} key={player.playerId}>
                    {/*<Col xs="4" style={{ textAlign: 'left' }}><Link to={playerLink} className="player-link-click">{player.name}</Link></Col>*/}
                    <Col xs="4" style={{ textAlign: 'left' }}>{player.name}</Col>
                    <Col xs="8">
                        <Row>
                            <Col xs="3" style={{ textAlign: 'right' }}>{player.matches}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{player.shoots}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{player.goals}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(player.catchesAVG).toFixed(2)}</Col>
                        </Row>
                        <Row>
                            <Col xs="3" style={{ textAlign: 'right' }}><img src={player.teamLogoLink} width="30px" height="30px" /></Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(player.shootsPerMatch).toFixed(2)}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(player.goalsPerMatch).toFixed(2)}</Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>{(player.catchesPerMatch).toFixed(2)}</Col>
                        </Row></Col>
                </Row>
               )
        })
    }

    return (<>
        <Row className="stats-header-row">
            <Col xs="4" style={{ textAlign: 'left' }}>Nombre</Col>
            <Col xs="8">
                <Row>
                    <Col xs="3" style={{ textAlign: 'right' }}>PJ</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>Tiros</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>Goles</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>%</Col>
                </Row>
                <Row>
                    <Col xs="3" style={{ textAlign: 'right' }}></Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>TxP</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>GxP</Col>
                    <Col xs="3" style={{ textAlign: 'right' }}>PxP</Col>
                </Row>
            </Col>
        </Row>
        {getGoalKeeperStatsRow()}
    </>


    )
}
