
import React, { Component, createContext } from "react";
import { getFireAuth } from "../services/firebase";
import { onAuthStateChanged, getAuth } from "firebase/auth";

export const UserContext = createContext({ user: null });
class UserProvider extends Component {
  state = {
    user: null
  };

    componentDidMount = async () => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({ user });
                // ...
            } else {
                this.setState({ user:null });
            }
        });
  };

  render() {
    const { user } = this.state;

    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
export default UserProvider;