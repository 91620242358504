import React, { Component } from 'react';
import { Spinner } from 'reactstrap';

export class Loading extends Component {
    static displayName = Loading.name;

    render() {
        return (
            <div style={{display:"flex", gap:"5px"}}>
                <Spinner
                    style={{color:"#8c98c1"}}
                    type="grow"
                    size="sm"
                >
                </Spinner>
                <Spinner
                    style={{color:"#8c98c1"}}
                    type="grow"
                    size="sm"
                >
                </Spinner>
                <Spinner
                    style={{color:"#8c98c1"}}
                    type="grow"
                    size="sm"
                >
                </Spinner>
            </div>
        );
    }
}
