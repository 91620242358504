import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getTeamSelected, isWatchScreen } from '../utils'
import fb from "../services/firebase"

export class Scorer extends Component {
    static displayName = Scorer.name;
    render() {
        const team = getTeamSelected();
        const { match, hideTitle } = this.props;
        const getMatchRow = (match) => {
            if (match.score1 === match.score2 && match.status?.toLowerCase() === "finalizado" && match.draw1 === false && match.draw2 === false) {
                if (match.penalties != null) {
                    const localGoals = match.penalties.filter(x => x.goalHome === 1);
                    const awayGoals = match.penalties.filter(x => x.goalAway === 1);
                    match.draw1 = localGoals > awayGoals;
                    match.draw2 = localGoals < awayGoals;
                }
            }
            return (<Row className={isWatchScreen() ? "result-team-row-watch" : "result-team-row"} key={`${match.name1}-${match.name1}-${match.category}-second`}>
                <Col xs="10" md="5"><img className={isWatchScreen() ? "result-team-logo-watch" : "result-team-logo"} src={match.logo1} alt="Home team logo"/>{match.name1}</Col>
                <Col xs="2" md="1" className={isWatchScreen() ? "result-score-watch" : "result-score"}>{match.draw1 && (<strong>*</strong>)}{match.score1 ?? "-"}</Col>
                <Col xs="10" md="5"><img className={isWatchScreen() ? "result-team-logo-watch" : "result-team-logo"} src={match.logo2} alt="Away team logo" />{match.name2}</Col>
                <Col xs="2" md="1" className={isWatchScreen() ? "result-score-watch" : "result-score"}>{match.draw2 && (<strong>*</strong>)}{match.score2 ?? "-"}</Col>
            </Row>)
        }
        return (
            <>
                {hideTitle !== true && (
                    <Row className="result-info-row" key={`${match.name1}-${match.name2}-${match.category}-first`}>
                        <Col>{match.category}</Col>
                        <Col>{match.urlVideo ? <Link to={{ pathname: match.urlVideo }} target="_blank" className="link-click">Ver partido</Link> : <></>}</Col>
                        <Col>{match.date}<br />{match.status}</Col>
                    </Row>
                )}
                {match.matchLink ? (
                    <Link to={match.matchLink} className="link-click" onClick={() => fb.logEvent("click_match", { name: `${match.category}-${match.name1}-${match.name2}`, team: team.name }) }>
                        { getMatchRow(match)}
                    </Link>
                )
                    : getMatchRow(match)
                }
                
            </>
        );
    }
}
