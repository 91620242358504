import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { getTeamSelected } from '../../utils'
import { ImageWithSpinner } from "../../components/ImageWithSpinner"
import fb from "../../services/firebase"
import "./Product.css"

export class Product extends Component {
    render() {
        const team = getTeamSelected();
        const { title, imageUrl, price, url, deliveryPrice } = this.props.product;

        return (<Col xs="12" md="4" xl="3">
            <a href={url} className="link-click" target="_blank" rel="nofollow noopener" onClick={() => fb.logEvent("click_product", { name: title, team: team.name })}>
                <div className="product-container">
                    <Row className="product-title">{title}</Row>
                    <Row className="product-image-container">
                        <ImageWithSpinner
                            className="product-image"
                            src={imageUrl}
                            alt={title}
                        />
                    </Row>
                    <Row>
                        <Col className="product-price">{price}</Col>
                        {deliveryPrice != null && (<Col className="product-delivery">Envío<br />{deliveryPrice.replace("por", "")}</Col>)} 
                    </Row>
                </div>
            </a>
          </Col>
        );
    }
}
