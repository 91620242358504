import React, { Component } from 'react';
import { Col, Row, Breadcrumb, BreadcrumbItem  } from "reactstrap"
import { Loading } from "../../components/Loading"
import { getGravatarImage, onShare } from "../../utils"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Helmet } from "react-helmet"
import "./Player.css"

export class Player extends Component {
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { player: null, loading: true, imageLoaded: false };
    }

    componentDidMount() {
        const { match, location } = this.props;
        const id = match.params.id;
        const isRFEP = location.search.split("=")[1];
        this.populatePlayerData(id, isRFEP);
    }

    componentWillUnmount() {
    }


    static renderPlayerData(data, that) {
        if (!data) return null;
        const gravatarImage = getGravatarImage(data.name);
        return (<div className='container-player'>
            <h1>{data.name}</h1>
            <Row>
                <Col xs="12" md="6" className='mt-2'>
                
                    {!that.state.imageLoaded && (<Skeleton width="250px" height="250px"/>) } 
                    <img onLoad={() => that.setState({...that.state, imageLoaded : true})} src={data.age >= 12 ? data.imageUrl : gravatarImage} className={that.state.imageLoaded ? "profile-image" : "profile-image-hidden"} alt="imagen de perfil"/>
                </Col>
                <Col xs="12" md="6" className='mt-4'>
                <div className="team-line">
                    <img src={data.teamUrl} className="team-image" alt="escudo del equipo"/>
                        <span>{data.teamName}</span>
                        <span>{data.age} años</span>
                 </div>
                {data.stats?.map((s) => {
                    return ( <>
                            <Row className='player-category-name'>
                            {s.name}
                            </Row>
                            <Row className="player-header-row">
                                <Col xs="4" style={{textAlign:'right'}}>Partidos</Col>
                                <Col xs="2" style={{textAlign:'right'}}>PT</Col>
                                <Col xs="2" style={{textAlign:'right'}}>G</Col>
                                <Col xs="2" style={{textAlign:'right'}}>A</Col>
                                <Col xs="2" style={{textAlign:'right'}}>PIM</Col>
                            </Row>
                            <Row>
                                <Col xs="4" style={{textAlign:'right'}}>{s.number}</Col>
                                <Col xs="2" style={{textAlign:'right'}}>{s.shoots}</Col>
                                <Col xs="2" style={{textAlign:'right'}}>{s.goals}</Col>
                                <Col xs="2" style={{textAlign:'right'}}>{s.assistances}</Col>
                                <Col xs="2" style={{textAlign:'right'}}>{s.fouls}</Col>
                            </Row>
                            </>
                            )
                        })}
                    </Col>
            </Row>
            
            </div>
        );
    }

    render() {
        let contents = Player.renderPlayerData(this.state.player, this);
        return (
            <>
                <Helmet>
                    <title>{this.state.player?.name ?? "Player" }</title>
                    <meta name="description" content="Información del jugador seleccionado" />
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
                <div style={{ width: "100%", marginBottom: "30px" }}>
                    <Row>
                        <Col>
                            <Breadcrumb>
                                <BreadcrumbItem active onClick={() => this.props.history.goBack()}>Atrás</BreadcrumbItem>
                            </Breadcrumb>
                        </Col>
                        <Col style={{ textAlign: "end", textDecoration: "none" }}>
                            <span className="share-link" onClick={() => onShare("Hockeyapp", document)}>Compartir</span>
                        </Col>
                    </Row> 
                    <p style={{ width: "100%" }}>{this.state.loading ? (
                        <Loading />
                    ) : null}
                    </p>
                    {contents}
                </div>
            </>
        );
    }

    async populatePlayerData(id, isRFEP) {
        const url = `api/hockey/player/${id}?isRFEP=${isRFEP}`;
        const response = await fetch(url);
        const data = await response.json();
        this.setState({ player: data, loading: false });
    }
}
