import React from 'react';
import './PlayersArea.css'
import { Avatar } from "./Avatar"

export function PlayersArea({ players, onClickPlayer }) {

    return (
        <div className="selected-players-area">
            {players.map(x => <Avatar key={x.id} player={x} size="100px" onClickPlayer={onClickPlayer} />)}
        </div>
    );
}
