import fb from "./services/firebase"
import MD5 from "crypto-js/md5";

export function getLocalStorage(variable, defaultValue) {
    let data = localStorage.getItem(variable);
    return data ?? defaultValue ;
}

export function setLocalStorage(variable, value){
    localStorage.setItem(variable, value);
}

export function scrollToJustAbove(element, margin = 100) {
    if (element !== null) {
        let dims = element.getBoundingClientRect();
        window.scrollTo(window.scrollX, dims.top - margin);
    }
}

export const isTeamSelected = () => {
    const team = getTeamSelected();
    return team?.team ? true : false;
}

export const getTeamSelected = () => {
    const team = JSON.parse(getLocalStorage("team", "{}"));
    return team;
}

export const isWatchScreen = () => {
    if (window) {
        // Pixels according to apple watch 44mm
        const isWatchScreen = window.innerHeight < 370 && window.innerWidth < 450;
        return isWatchScreen;
    }
    return false;
}

export const onShare = async (title, document, text) => {
        const team = getTeamSelected();
        const url = document.querySelector("link[rel=canonical]")
            ? document.querySelector("link[rel=canonical]").href
            : document.location.href;

        fb.logEvent("click_share", { name: url, team: team.name })
        try {
            await navigator
                .share({
                    title,
                    url,
                    text
                })
            alert("Gracias por compartir");
        } catch (err) {
            navigator.clipboard.writeText(url).then(() => {
                alert("Se ha copiado el enlace en el portapapeles");
            });
        }
}

export const getGoogleMapsLink = (latitude, longitude) => {
    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
}

export const getDistinctValues = (array, param) =>{
    const distinctValues = new Set(array.map(obj => obj[param]));
    return Array.from(distinctValues);
}

export const getGravatarImage = (name) => {
    return `https://gravatar.com/avatar/${MD5(name)}?r=g&d=robohash&s=250`
}