import React from 'react';
import { Col, Row } from "reactstrap"
import { Link } from 'react-router-dom';
import { getTeamSelected } from '../../utils'
import fb from "../../services/firebase"

export const PlayerStatsTable = ({ playerStats, isRFEP }) => {
    const onPlayerClick = (player) => {
        const team = getTeamSelected();
        fb.logEvent("click_player_stats", { name: player.name, team: team.name })
    }
    const getPlayerStatsRow = () => {
        return playerStats.map((player, i) => {
            const rowClassName = i % 2 === 0 ? "row-odd" : "row-even";
            const playerLink = `/player/${player.playerId}?isRFEP=${isRFEP}`;
            return (<Row className={rowClassName} key={i + player.playerId}>
                <Col xs="4" style={{ textAlign: 'left' }}><Link to={playerLink} className="player-link-click" onClick={ () => onPlayerClick(player)}>{player.name}</Link></Col>
                <Col xs="2" style={{ textAlign: 'right' }}>{player.number}</Col>
                <Col xs="2" style={{ textAlign: 'right' }}>{player.goals}</Col>
                <Col xs="2" style={{ textAlign: 'right' }}>{player.assistances}</Col>
                <Col xs="2" style={{ textAlign: 'right' }}>{player.fouls}</Col>
            </Row>)
        })
    }

    return (<>
        <Row className="stats-header-row">
            <Col xs="4" style={{ textAlign: 'left' }}>Nombre</Col>
            <Col xs="2" style={{ textAlign: 'right' }}>PJ</Col>
            <Col xs="2" style={{ textAlign: 'right' }}>G</Col>
            <Col xs="2" style={{ textAlign: 'right' }}>A</Col>
            <Col xs="2" style={{ textAlign: 'right' }}>PIM</Col>
        </Row>
        {getPlayerStatsRow()}
    </>


    )
}
