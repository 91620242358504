import React, { Component } from 'react';
import { onShare } from "../../utils"
import { Helmet } from "react-helmet"

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (<>
            <Helmet>
                <title>Información</title>
                <meta name="description" content="Info de la web" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div>
                <h3>Página <strong>no oficial</strong></h3>
                <p>Todos los datos son extraídos de la web oficial de la <a href="http://www.hockeylinea.fpcv.es/" target="_blank" rel="noopener noreferrer nofollow">Federació de Patinatge Comunitat Valenciana</a>&nbsp;
                    y de la web oficial de la <a href="http://www.hockeylinea.fep.es/" target="_blank" rel="noopener noreferrer nofollow">Real Federación Española de Patinaje</a>.
                    El ánimo de esta web paralela a las oficiales es la agregación de los resultados y clasificaciones de las 2 federaciones y poder tener una versión adaptada a dispositivos móviles y poder disfrutar siguiendo los partidos.</p>
            </div>
            <div>
                <p>Tanto la página de resultados como las páginas de los partidos se actualiza automáticamente cada 15 segundos.</p>
            </div>
            <div>
                <p>Comparte la web haciendo click <span className="share-link" onClick={() => onShare("Hockeyapp", document)}>aquí</span></p>
            </div>
        </>
        );
    }
}
