import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getAuth, signInAnonymously, updateProfile } from "firebase/auth";
import { getAnalytics, logEvent, setCurrentScreen } from "firebase/analytics";
import { getRemoteConfig, getValue, fetchAndActivate } from "firebase/remote-config";
var fire;
var analytics;
var remoteConfig;

const firebaseConfig = {
    apiKey: "AIzaSyAm7fEthB0O31hgpBVRYbGdYW_llDkpXvo",
    authDomain: "micartera-7efed.firebaseapp.com",
    databaseURL: "https://micartera-7efed-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "micartera-7efed",
    storageBucket: "micartera-7efed.appspot.com",
    messagingSenderId: "660472661105",
    appId: "1:660472661105:web:a3c45c39f8be356a9487d0",
    measurementId: "G-K2ZFJ3NDM1"
};

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    ) ||
    window.location.hostname.includes('hockeyapp-feature-)')
);


class FirebaseClass {
    constructor () {
        fire = initializeApp(firebaseConfig);
        remoteConfig = getRemoteConfig(fire);

        if (!isLocalhost) {
            analytics = getAnalytics(fire);
        }
        
    }
    setCurrentScreen(screenName) {
        if (!isLocalhost) {
            setCurrentScreen(analytics, screenName);
        }
    }
    logEvent(eventName, object) {
        if (!isLocalhost) {
            logEvent(analytics, eventName, object);
        } else {
            console.log(eventName, object)
        }
    }
}

const fb = new FirebaseClass();
export default fb;

export const getFireConfig = async (configName) => {
    fire = initializeApp(firebaseConfig);
    remoteConfig = getRemoteConfig(fire);
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000; //60 minutos
    remoteConfig.defaultConfig = {
        "isRegulationPageActive": false,
        "isProductsPageActive": false,
        "isGamePageActive": false,
    };
    const val = await fetchAndActivate(remoteConfig).then(x => {
        const value = getValue(remoteConfig, configName);
        return value._value;
    });
    return val;
}

export const getFireAuth = async () => {
    const auth = getAuth();
    return auth;
}

export const UpdateFireAuth = (displayName) => {

    const auth = getAuth();
    updateProfile(auth.currentUser, {
        displayName
    })

    const db = getDatabase();
    set(ref(db, 'hockeyData/users/' + auth.currentUser.uid), {
        name: displayName,
    });
}