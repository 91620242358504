import React, { useState, useContext } from 'react';
import { getFireAuth } from "../../services/firebase";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

export function SignUp() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");

    const signUp = () => {
        if (password !== password2) {
            alert("El password no coincide")
        } else {
            getAuth.languageCode = "es";
            createUserWithEmailAndPassword(getAuth(), email, password)
                .then((userCredential) => {
                    if (userCredential.user) {
                        window.location.href = "/login";
                    }
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    alert(errorMessage)
                });
        }
    }

    return <Container className="d-flex justify-content-center" style={{ marginTop: '40px' }}>
        <Row className="w-100 justify-content-center">
            <Col xs="12" md="8" lg="6">
                <div className="border p-4 rounded" style={{ margin: '0 auto' }}>
                    <h2 className="text-center" style={{ fontWeight: '600', color: '#2D3748' }}>
                        Bienvenido
                    </h2>
                    <p className="text-center" style={{ color: '#4A5568', fontWeight: '500' }}>
                        Registrese para continuar
                    </p>
                    <Form>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password2">Confirmar password</Label>
                            <Input
                                type="password"
                                name="password2"
                                id="password2"
                                placeholder="Confirm your password"
                                onChange={(e) => setPassword2(e.target.value)}
                            />
                        </FormGroup>
                        <Button color="primary" block onClick={signUp}>
                            Registrarse
                        </Button>
                    </Form>
                    <Row className="mt-4 justify-content-center">
                        <Col xs="auto">
                            <span> He cambiado de opinión </span>
                            <a href="/" className="text-primary" style={{ fontWeight: '500' }}>
                                Volver a la app
                            </a>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>;
}