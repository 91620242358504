import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap"
import { Loading } from "../../components/Loading"
import { setLocalStorage, isTeamSelected, getLocalStorage } from "../../utils"
import fb from "../../services/firebase"
import { Helmet } from "react-helmet"
import "./Teams.css"

export class Teams extends Component {
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { teams: [], loading: true };
    }

    componentDidMount() {
        this.populateTeamsData();
    }

    static renderTeamsTable(teams) {

        const onClickTeam = (team) => {
            fb.logEvent("click_team", { name: team.name })
            setLocalStorage("team", JSON.stringify(team))
            var from = getLocalStorage("changeTeamFrom", "");
            window.location = `/${from === "results" ? "" : from}`;
        }

        const getTeamData = (team) => {
            return (<Col className="team-col" onClick={() => onClickTeam(team) }>
                <img src={team.logoUrl} loading="lazy" width="75px" height="75px" alt={`${team.name} logo` } />
                <Row><span>{team.name}</span></Row>
            </Col>)
        }
        return (<>
            <Container className="teams-container">
                <Row>
                    {teams.map(team => {
                        return getTeamData(team);
                    })}
                </Row>
            </Container>
        </>
        );
    }

    render() {
        let contents = Teams.renderTeamsTable(this.state.teams, this.state.loading);

        return (<>
            <Helmet>
                <title>Equipos hockey línea Comunidad Valenciana</title>
                <meta name="description" content="Elige tu equipo favorito de entre los siguientes: llops vila-real, taurons nules, dinos borriol, orcas oropesa, hockey línea castellon" />
                <meta name="keywords" content="llops vila-real, taurons nules, dinos borriol, orcas oropesa, hockey línea castellon" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            {!isTeamSelected() ? (<div className="title-div">
                <Row className="title">Hockey App</Row>
                <Row>Elige tu equipo favorito</Row>
            </div>) : (<div>
                <h1 id="tabelLabel">Equipos</h1>
                <h2>{this.state.loading ? (
                    <Loading />
                ) : "Selecciona otro equipo"}</h2>
            </div>)}
            {contents}
        </>
        );
    }

    async populateTeamsData() {
        const response = await fetch('api/hockey/teams');
        const data = await response.json();
        this.setState({ teams: data, loading: false });
    }
}
