import React, { Component } from 'react';
import { Badge } from 'reactstrap';

export class PenaltySquare extends Component {
    static displayName = PenaltySquare.name;
    render() {
        const { score } = this.props;
        let color = "secondary";
        if (score === 0) {
            color = "danger";
        } else if (score === 1) {
            color = "success";
        }
        return (
            <Badge color={color}>&nbsp;</Badge>          
        );
    }
}
