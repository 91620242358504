import React from 'react';
import { Row } from 'reactstrap'
import { Scorer } from "../../components/Scorer"



export const CalendarTable = ({ calendar }) => {
    return (
        <>
            {calendar.map((cal, i) => {
                const dateTime = `${cal.date} - ${cal.time != null ? cal.time : "Hora por determinar"}`
                const matchLink = cal.matchId != null ? `/match/${cal.matchId}` : null;
                if (cal.headJornada) {
                    return (<Row className="calendar-table-head"><span>{cal.headJornada}</span></Row>)
                }
                return (
                    <Scorer key={`${cal.matchId}-${cal.teamName1}-${cal.teamName2}`} match={
                        {
                            ...cal,
                            date: dateTime,
                            name1: cal.teamName1,
                            name2: cal.teamName2,
                            logo1: cal.teamIconUrl1,
                            logo2: cal.teamIconUrl2,
                            category: cal.place,
                            draw1: cal.bonus1,
                            draw2: cal.bonus2,
                            urlVideo: cal.urlVideo,
                            matchLink: matchLink,
                        }} />
                )
            })}
        </>
    )
}
