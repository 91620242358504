import React, { Component } from 'react';
import "./Calendar.css"
import { Loading } from "../../components/Loading"
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap"
import { getLocalStorage, setLocalStorage, scrollToJustAbove } from "../../utils"
import { CalendarTable } from '../../components/Calendar/CalendarTable';

export class Calendar extends Component {
    static displayName = Calendar.name;
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { calendars: [], loading: true };
    }

    componentDidMount() {
        this.populateClassificationData();
        try {
            this.intervalID = setInterval(async () => {
                this.setState({ ...this.state, loading: true });
                this.populateClassificationData();
            }, 60000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }


    static renderCalendarTable(divisions) {

        let lastOpenAccordion = parseInt(getLocalStorage("calendar-accordion", 0));
        return (<UncontrolledAccordion
            defaultOpen={[
                lastOpenAccordion
            ]}
        >
            {divisions.map((division, index) => {
                let accordionId = index + 1;
                return (
                    <AccordionItem onClick={() => setLocalStorage("calendar-accordion", accordionId)}>
                        <AccordionHeader targetId={accordionId} className={`accordion-${accordionId}`}>
                            {division.name}
                        </AccordionHeader>
                        <AccordionBody accordionId={accordionId}>
                            <CalendarTable calendar={division.calendar} />
                    </AccordionBody>
                  </AccordionItem>)
            })}
            
          </UncontrolledAccordion>)
    }

    render() {
        let contents = Calendar.renderCalendarTable(this.state.calendars);
        setTimeout(() => {
            let lastOpenAccordion = parseInt(getLocalStorage("calendar-accordion", 0));
            let accordionClass = `.accordion-${lastOpenAccordion}`;
            scrollToJustAbove(document.querySelector(accordionClass))
        }, 500)
        return (
            <div className='container-classification'>
                <h1>Calendarios</h1>
                {this.state.loading ? (
                    <Loading />
                ) : <p>&nbsp;</p>}
                {contents}
            </div>
        );
    }

    async populateClassificationData() {
        const response = await fetch('api/division/calendar');
        const data = await response.json();
        this.setState({ calendars: data, loading: false });
    }
}
