import React, { useState, useContext } from 'react';
import { getFireAuth } from "../../services/firebase";
import { useHistory } from "react-router-dom"
import { sendPasswordResetEmail, signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';


export function Login() {

    const history = useHistory();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const searchParams = new URLSearchParams(window.location.search);

    const recoverPassword = () => {
        var actionCodeSettings = {
            url: 'https://hockeylinea.online/login'
        };
        getAuth.languageCode = "es";
        sendPasswordResetEmail(getAuth(), email, actionCodeSettings)
            .then(() => {
                alert("Se ha enviado un enlace a su email para restaurar la contraseña");
            })
    }

    const logIn = () => {
        signInWithEmailAndPassword(getAuth(),email, password)
            .then(() => {
                const url = searchParams.get("url")
                var location = "/"
                if (url) {
                    location = `/${url}`;
                }
                history.push(location, {from:"login"})
            })
            .catch(() => {
                alert("Los datos de usuario o contraseña no es válido")
            });
    }
    return <Container className="d-flex justify-content-center" style={{ marginTop: '40px' }}>
        <Row className="w-100 justify-content-center">
            <Col xs="12" md="8" lg="6">
                <div className="border p-4 rounded" style={{ margin: '0 auto' }}>
                    <h2 className="text-center" style={{ fontWeight: '600', color: '#2D3748' }}>
                        Bienvenido
                    </h2>
                    <p className="text-center" style={{ color: '#4A5568', fontWeight: '500' }}>
                        Haga login en la aplicación
                    </p>
                    <Form>
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                placeholder="Enter your password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button color="link" className="p-0" onClick={recoverPassword}>
                                Recuperar password
                            </Button>
                        </FormGroup>
                        <Button color="primary" block onClick={logIn}>
                            Acceder
                        </Button>
                    </Form>
                    <Row className="mt-4 justify-content-center">
                        <Col xs="auto">
                            <span> Soy nuevo </span>
                            <a href="/signup" className="text-primary" style={{ fontWeight: '500' }}>
                                Registrarse
                            </a>
                        </Col>
                    </Row>
                    <Row className="mt-2 justify-content-center">
                        <Col xs="auto">
                            <span> He cambiado de opinión </span>
                            <a href="/" className="text-primary" style={{ fontWeight: '500' }}>
                                Volver a la app
                            </a>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    </Container>;
}