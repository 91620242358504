import { getLocalStorage } from '../utils'

export async function getPlayers(divisionId, userId) {
    const team = JSON.parse(getLocalStorage("team", "{}"));
    const response = await fetch('api/game/players/' + divisionId + '/' + userId, { headers: { "teamName": team.team } });
    const data = await response.json();
    return data;
}

export async function getRanking(divisionId) {
    const team = JSON.parse(getLocalStorage("team", "{}"));
    const response = await fetch('api/game/ranking/' + divisionId, { headers: { "teamName": team.team } });
    const data = await response.json();
    return data;
}

export async function postAddPlayers(players) {
    const team = JSON.parse(getLocalStorage("team", "{}"));
    const response = await fetch('api/game/adduserplayers',
        {
            method: "POST",
            headers: { "teamName": team.team, 'Content-Type': 'application/json;' },
            body: JSON.stringify(players)
        });
    const data = await response.json();
    return data ;
}