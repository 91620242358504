import React from 'react';
import './Ranking.css'
import { Container, Table } from "reactstrap"

export function Ranking({ data }) {

    return (
        <Container className="ranking">
            <Table
                borderless
                responsive
                size="sm"
                striped
            >
                <thead>
                    <tr>
                        <th>
                            Posición
                        </th>
                        <th>
                            Jornadas
                        </th>
                        <th>
                            Nombre
                        </th>
                        <th>
                            Puntuación
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(x => {
                        return (<tr key={x.position}>
                            <td scope="row" className="table-numbers">
                                { x.position}
                            </td>
                            <td className="table-numbers">
                                {x.games}
                            </td>
                            <td>
                                {x.name}
                            </td>
                            <td className="table-numbers">
                                {x.points}
                            </td>
                        </tr>)
                    })}
                </tbody>
            </Table>
        </Container>
    );
}
