import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import fb from "../../services/firebase"
import { getTeamSelected } from '../../utils'
import { useParams, useHistory } from "react-router-dom"
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, Breadcrumb, BreadcrumbItem, UncontrolledAlert } from "reactstrap"
import { Loading } from "../../components/Loading"
import { Avatar } from "../../components/Game/Avatar"
import { PlayersArea } from "../../components/Game/PlayersArea"
import { Profile } from "../../components/Game/Profile"
import { Ranking } from "../../components/Game/Ranking"
import { getFireConfig } from "../../services/firebase"
import { getPlayers, getRanking, postAddPlayers } from "../../services/api"
import { UserContext } from "../../context/UserProvider";

import "./Game.css"

export function Game() {

    const user = useContext(UserContext);
    const [players, setPlayers] = useState([]);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [arePlayersLocked, setArePlayersLocked] = useState(false);
    const [loading, setLoading] = useState(true);
    const [usersSaved, setUsersSaved] = useState(false);
    const [ranking, setRanking] = useState([]);
    const [isGamePageActive, setIsGamePageActive] = useState(false);
    const [currentTab, setCurrentTab] = useState("1");

    const { id } = useParams()
    const history = useHistory();

    const isThereUserName = user?.displayName?.length > 0;

    useEffect(() => {
        const response = async () => {
            await getFireConfig("isGamePageActive").then((res) => {
                const isActive = res !== "false";
                if (isActive) {
                    loadData();
                    setIsGamePageActive(true);
                    const team = getTeamSelected();
                    fb.logEvent("click_game", { name: id, team: team.name })
                } else {
                    setLoading(false);
                }
            })
        }
        response();
    }, [user])


    useEffect(() => {
        if (!isThereUserName) {
            setCurrentTab("3");
        } else {
            setCurrentTab("1");
        }
    }, [isThereUserName])

    const goBack = () => {
        const from = history.location.state?.from;
        if (from && history.length > 1) {
            history.go(-2)
        }
        history.goBack()
    }

    const loadData = async () => {
        if (user) {
            const data = await getPlayers(id, user.uid);
            setPlayers(data.playerList.filter(x => !data.selectedPlayers.includes(x.id)));
            setSelectedPlayers(data.playerList.filter(x => data.selectedPlayers.includes(x.id)))
            setArePlayersLocked(data.arePlayersLocked);
            setLoading(false);
            const rankingData = await getRanking(id);
            setRanking(rankingData);
        } else {
            setLoading(false);
        }
    }

    const onChangeTab = (newTab) => {
        setCurrentTab(newTab);
    }

    const sortPlayers = (a, b) => {
        return a.name > b.name ? 1 : -1
    }
    const playersRow = players.filter(x => !selectedPlayers.includes(x)).sort(sortPlayers)

    const addSelectedPlayer = (player) => {
        if (!arePlayersLocked && selectedPlayers.length < 4) {
            setPlayers(players.filter(x => x.id != player.id))
            setSelectedPlayers([...selectedPlayers, player])
            setUsersSaved(false);
        }
    }

    const removeSelectedPlayer = (player) => {
        if (!arePlayersLocked) {
            setSelectedPlayers(selectedPlayers.filter(x => x.id != player.id))
            setPlayers([...players, player])
            setUsersSaved(false);
        }
    }

    const savePlayers = (player) => {
        const addPlayers = {
            userId: user.uid,
            divisionId: id,
            players: selectedPlayers.map((x) => x.id)
        };
        postAddPlayers(addPlayers).then(() => { setUsersSaved(true) });
    }

    if (loading) {
        return <Loading />;
    }

    if (!user) {
        return (
            <Container className="game-container">
                Debe hacer <Link to={`/login?url=game/${id}`}>login</Link> para acceder al juego
            </Container>)
    }

    if (!isGamePageActive)
    return <p>El juego no está activo en estos momentos.</p>

    return (
        <Container className="game-container">
            <Row>
                <Col xs="9">
                    <Breadcrumb>
                        <BreadcrumbItem active onClick={() => goBack()}>Atrás</BreadcrumbItem>
                    </Breadcrumb>
                </Col>
                <Col xs="3">
                    <Link to="/game/docs/rules" className="nav-link">Reglas</Link>
                </Col>
            </Row>
            <h1>GAME</h1>
            {!isThereUserName && (
                <UncontrolledAlert color="info" fade>
                    Introduce y guarda tu nombre (o nickname) desde "Mi perfil" para poder acceder al juego
                </UncontrolledAlert>
            )}
            {arePlayersLocked && (
                <UncontrolledAlert color="info" fade>
                    No puedes cambiar los jugadores durante la jornada de juego.
                </UncontrolledAlert>
            )}
            <Nav pills fill>
                <NavItem>
                    <NavLink
                        className={currentTab === "1" ? "active" : ""}
                        onClick={() => onChangeTab("1")}
                        disabled={!isThereUserName}
                    >
                        Mis jugadores
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={currentTab === "2" ? "active" : ""}
                        onClick={() => onChangeTab("2")}
                    >
                        Clasificación
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={currentTab === "3" ? "active" : ""}
                        onClick={() => onChangeTab("3")}
                    >
                        Mi perfil
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={currentTab} className="game-tab-content">
                <TabPane tabId="1">
                    <div style={{ width: "100%", marginBottom: "30px", display: "grid" }}>
                        <div className="players-row-selector">
                            {playersRow.map(x => {
                                return (<div key={ x.id} onClick={() => addSelectedPlayer(x)}><Avatar player={x} size="100px" /></div>)
                            })}
                        </div>
                        <PlayersArea players={selectedPlayers} onClickPlayer={removeSelectedPlayer} />
                        <div className="save-container">
                            {usersSaved ? (
                                <UncontrolledAlert color="success" fade>
                                    Jugadores guardados
                                </UncontrolledAlert>
                            ) : (<Button className="submit-button" disabled={arePlayersLocked} onClick={() => savePlayers()}>Guardar jugadores</Button>)}
                        </div>
                    </div>
                </TabPane>
                <TabPane tabId="2">
                    <Ranking data={ranking} />
                </TabPane>
                <TabPane tabId="3">
                    <Profile user={user} setCurrentTab={setCurrentTab} />
                </TabPane>
            </TabContent>
        </Container>
    );
}
