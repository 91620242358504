import React from 'react';
import { Col, Row } from "reactstrap"


export const ClassificationTable = ({ division, index, isRfep }) => {
    return (
        <>
            <Row style={{ marginTop: "1rem" }}> {division.title}</Row>
            <Row>
                <Col xs="5"></Col>
                <Col xs="1" style={{ textAlign: 'right' }}>Pts</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>Bon</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>PJ</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>PG</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>PE</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>PP</Col>
                <Col xs="1" style={{ textAlign: 'right' }}>Gav</Col>
            </Row>
            {division.classification.map((cla, i) => {
                const rowClassName = i % 2 === 0 ? "row-odd" : "row-even"
                return (<Row className={rowClassName} key={i + cla.teamName}>
                    <Col xs="5"><Col className='classification-team-name'><img src={cla.teamIconUrl} width="20px" height="20px" alt={`${cla.teamName} logo` } /> {cla.position} {cla.teamName}</Col></Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.points}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.bonus}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.pj}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.pg}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.pe}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.pp}</Col>
                    <Col xs="1" style={{ textAlign: 'right' }}>{cla.gav}</Col>
                </Row>)
            })}
        </>
    )
}
