import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Slider from "react-slick";
import { getTeamSelected } from '../utils'
import fb from "../services/firebase"
import "./RelatedMatches.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export class RelatedMatches extends Component {
    render() {
        const team = getTeamSelected();
        const { relatedMatches } = this.props;

        const getMatches = () => {
            return relatedMatches.map(x => {
                const matchLink = x.id ? `/match/${x.id}` : null;
                if (matchLink) {
                    return (<a href={matchLink} className="link-click" onClick={() => onClick(x)}>
                        {getMatch(x)}
                    </a>)
                } else {
                    return getMatch(x);
                }
            })
        }
        const getMatch = (match) => {
            if (match.score1 === match.score2 && match.status?.toLowerCase() === "finalizado" && match.draw1 === false && match.draw2 === false) {
                if (match.penalties !== null) {
                    const localGoals = match.penalties.filter(x => x.goalHome === 1);
                    const awayGoals = match.penalties.filter(x => x.goalAway === 1);
                    match.draw1 = localGoals > awayGoals;
                    match.draw2 = localGoals < awayGoals;
                }
            }

            return (

                match.score1 !== null ? (<div>
                    <Row className="related-matches-status">{match.status}</Row>
                    <Row className="related-matches-team-row" key={`${match.name1}-${match.name1}-${match.date}-second`}>
                        <Col xs="3"><img className="related-matches-team-logo" src={match.logo1} /></Col>
                        <Col xs="2" className="related-matches-score">{match.draw1 && (<strong>*</strong>)}{match.score1}</Col>
                        <Col xs="2" className="related-matches-score"> - </Col>
                        <Col xs="2" className="related-matches-score">{match.draw2 && (<strong>*</strong>)}{match.score2 ?? ""}</Col>
                        <Col xs="3"><img className="related-matches-team-logo" src={match.logo2} /></Col>
                    </Row>
                        <Row>
                            <Col xs="6" className="related-matches-team-name">{match.name1}</Col>
                            <Col xs="6" className="related-matches-team-name">{match.name2}</Col>
                        </Row>
                </div>)
                    : (<div>
                        <Row className="related-matches-status">{match.status}</Row>
                        <Row className="related-matches-team-row" key={`${match.name1}-${match.name1}-${match.date}-second`}>
                        <Col xs="3"><img className="related-matches-team-logo" src={match.logo1} /></Col>
                        <Col xs="6">{match.date}</Col>
                        <Col xs="3"><img className="related-matches-team-logo" src={match.logo2} /></Col>
                    </Row>
                        <Row>
                            <Col xs="6" className="related-matches-team-name">{match.name1}</Col>
                            <Col xs="6" className="related-matches-team-name">{match.name2}</Col>
                        </Row>
                    </div>)
                )
        }
        const onClick = (match) => {
            fb.logEvent("click_match", { name: `${match.category}-${match.name1}-${match.name2}`, team: team.name })
        }

        const settings = {
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            autoplay: true,
            speed: 1000,
            vertical: true,
            autoplaySpeed: 5000,
        };

        return (<div className="related-matches-container">
            {relatedMatches.length > 1 ? (<Slider {...settings}>
                {getMatches()}
            </Slider>) :
                getMatches()
            }
            </div>
        );
    }
}
