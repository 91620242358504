import React, { useState, useRef, useEffect } from 'react'
import { Spinner } from 'reactstrap'

export function ImageWithSpinner({
    src,
    onLoad,
    ...props
}: {
    onLoad?: () => void,
} & DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>) {
    const [imgSrc, setImgSrc] = useState(null)
    // Store the onLoad prop in a ref to stop new Image() from re-running
    const onLoadRef = useRef(onLoad)
    useEffect(() => {
        onLoadRef.current = onLoad
    }, [onLoad])
    useEffect(() => {
        const img = new Image()
        img.onload = () => {
            setImgSrc(src)
            if (onLoadRef.current) {
                onLoadRef.current()
            }
        }
        img.src = src
    }, [src])

    if (imgSrc == null) {
        return <div {...props} style={{
            justifyContent: 'center', alignItems: 'center', display: 'flex'
        }}><Spinner
                style={{ color: "#8c98c1" }}
                type="grow"
        /></div>
    }
    return <img src={imgSrc} {...props} />
}