import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { getLocalStorage, isWatchScreen } from "../utils"
import './Layout.css';

export class Layout extends Component {

    render() {
        const getLogoUrl = () => {
            const team = JSON.parse(getLocalStorage("team", "{}"));
            if (team.logoUrl) {
                return team.logoUrl;
            } else {
                return "/images/wolf_icon.jpg";
            }
        }

    return (
        <div>
            {!isWatchScreen() && (<NavMenu />)}
            <img className="wolf-background" alt="background logo" loading="lazy" src={getLogoUrl()} />
         <Container>
          {this.props.children}
        </Container>
      </div>
    );
  }
}
