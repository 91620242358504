import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap"
import { Loading } from "../../components/Loading"
import { getLocalStorage, setLocalStorage } from "../../utils"
import { Helmet } from "react-helmet"
import { getTeamSelected } from '../../utils'
import fb from "../../services/firebase"
import './Divisions.css';

export class Divisions extends Component {
    static displayName = Divisions.name;
    constructor(props) {
        super(props);
        this.state = {
            divisions: [], loading: true, team: {} };
    }

    componentDidMount() {
        this.populateDivisionsData();
    }

    componentWillUnmount() {
    }


    static renderDivisionsTable(divisions, isLoading) {
        const onClickDivision = (division) => {
            const team = getTeamSelected();
            fb.logEvent("click_division", { name: division.name , team: team.name});
        }
        const getDivisionData = (division) => {
            const divisionLink = division.divisionId ? `/division/${division.divisionId}` : null;
            const getLogo = (logo) => {
                return <Col xs="2" key={division.divisionId + logo} md="1" lg="1"><img src={logo} className="division-team-logo" alt="teams logo"/></Col>
            }
            return (
                <a href={divisionLink} className="link-click" onClick={ () => onClickDivision(division)}>
                    <Row key={division.divisionId} className="division-row">
                        <Row className="division-row-logos">{division.teamLogos.map(logo => getLogo(logo))}</Row>
                        <Row><h4>{division.name}</h4></Row>
                    </Row>
                </a>
            )
        }
        return (<>
            <Container className="result-division-container">
                {divisions.map(division => {
                    return getDivisionData(division);
                })}
            </Container>
        </>
        );
    }

    render() {
        let contents = Divisions.renderDivisionsTable(this.state.divisions, this.state.loading);

        return (
            <>
                <Helmet>
                    <title>Ligas Hockey línea Comunidad Valenciana</title>
                    <meta name="description" content="Clasficación ligas y torneos de hockey línea de los equipos de la provincia de Castellón" />
                    <meta name="keywords" content="clasificación hockey, liga hockey linea, roller hockey, clasificación llops vila-real, clasificación taurons nules, clasificación dinos borriol, clasificación orcas oropesa, clasificación hockey línea castellon" />
                    <meta name="robots" content="index, follow" />
                </Helmet>
                <div style={{ width: "100%", marginBottom: "30px" }}>
                    <Row className="division-container-header">
                        <Row>
                            <Col xs="9">
                                <h1 id="tabelLabel">Ligas Hockey</h1>
                            </Col>
                            <Col xs="3" className="result-choose-team ">
                                <Link to={{ pathname: "/teams" }} style={{ textDecoration: "none" }} onClick={() => setLocalStorage("changeTeamFrom", "divisions")}>
                                    Elige tu equipo
                                </Link>
                             </Col>
                        </Row>
                        <Row>
                            {this.state.loading ? (
                                <Loading />
                            ) : <h2>{this.state.team.name ?? "Llops Vila-real"}</h2>}
                        </Row>
                    </Row>
                   
                    {contents}
                </div>
            </>
        );
    }

    async populateDivisionsData() {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch('api/division/list', { headers: { "teamName": team.team } });
        const data = await response.json();
        this.setState({ divisions: data, loading: false, team:team });
    }
}
