import React, { useRef, useState } from 'react';
import './Profile.css'
import { Container, Form, Row, Col, FormGroup, Label, Input, Button, FormFeedback } from "reactstrap"
import { UpdateFireAuth } from "../../services/firebase"

export function Profile({ user, setCurrentTab }) {

    const nameRef = useRef(null);
    const [isValid, setIsValid] = useState(null);

    const updateName = async() => {
        const name = nameRef.current.value;
        if (name.length > 2) {
            await UpdateFireAuth(name);
            setIsValid(true);
            setCurrentTab("3");
        } else {
            setIsValid(false);
        }
    }

    return (
        <Container className="profile">
            <Form className="profile-form">
                <FormGroup row>
                    <Label
                        for="name"
                        sm={2}
                    >
                        Nombre
                    </Label>
                    <Col sm={10}>
                        <Input
                            innerRef={nameRef}
                            id="name"
                            name="name"
                            placeholder={user?.displayName}
                            type="text"
                            valid={isValid}
                            invalid={isValid == null ? undefined : !isValid}
                        />
                        <FormFeedback>
                            El nombre debe tener al menos 3 carácteres
                        </FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label
                        for="id"
                        sm={2}
                    >
                        UserId
                    </Label>
                    <Col sm={10}>
                        <Input
                            id="userid"
                            name="userid"
                            type="text"
                            value={user?.uid}
                            disabled
                        />
                    </Col>
                </FormGroup>
                <Button className="submit-button" onClick={() => updateName()}>Cambiar nombre</Button>
            </Form>
        </Container>
    );
}
