import React, { Component } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Button, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap"
import { Loading } from "../../components/Loading"
import { getLocalStorage, setLocalStorage, getDistinctValues } from "../../utils"
import { Helmet } from "react-helmet"
import { getFireConfig } from "../../services/firebase"
import './products.css';
import { Product } from '../../components/Product/Product';

export class Products extends Component {
    constructor(props) {
        super(props);
        let lastActiveTab = getLocalStorage("products-tab", 1);
        this.state = {
            products: [],
            loading: true,
            activeTab: parseInt(lastActiveTab),
            isProductsPageActive: null,
        };
    }

    componentDidMount() {
        this.populateProductsData();

        getFireConfig("isProductsPageActive").then((res) => {
            this.setState({ ...this.state, isProductsPageActive: res !== "false" })
        })
    }

    static renderProducts(products, isLoading, that) {
        const productTypes = getDistinctValues(products, "type").sort();
        const onChangeTab = (newTab) =>
        {
            that.setState({ ...that.state, activeTab: newTab })
            setLocalStorage("products-tab", newTab)
        }

        if (isLoading || that.state.isProductsPageActive == null) {
            return null;
        }

        const getNavItems = (type, index) => {
            const tabNumber = index + 1;
            return (<NavItem>
                <NavLink
                    className={that.state.activeTab === tabNumber ? "active" : ""}
                    onClick={() => onChangeTab(tabNumber)}
                >
                    {type}
                </NavLink>
            </NavItem>)
        }

        const getTabContent = (type, index) => {
            const tabNumber = index + 1;
            const productsType = products.filter(x => x.type === type);
            return (<TabPane tabId={tabNumber}>
                <Container className="products-tab-pane-container">
                    <Row>
                        {productsType.map(x => {
                            return <Product product={ x } />
                        })}
                    </Row>
                </Container>
            </TabPane>)
        }


        return (<>
            <Container className="products-container">
                <Nav pills fill>
                    {productTypes.map((p, i) => getNavItems(p, i)) }
                </Nav>
                <TabContent activeTab={that.state.activeTab} className="products-tab-content">
                    {productTypes.map((p, i) => getTabContent(p, i))}
                </TabContent>
            </Container>
        </>
        );
    }

    render() {
        let contents = Products.renderProducts(this.state.products, this.state.loading, this);

        const getBody = () => {
            return (<p>{this.state.loading || this.state.isProductsPageActive == null ? (
                <Loading />
            ) : (this.state.isProductsPageActive === false ? "Lo sentimos, el servicio no está disponible en estos momentos" : this.state.products.length > 0 ? contents : "No hay productos para mostrar en estos momentos")}</p>)
        }

        return (
            <>
                <Helmet>
                    <title>Productos hockey línea. Material y accesorios</title>
                    <meta name="description" content="Productos de hockey línea. Las mejores ofertas de hockey línea en Amazon. Compra material y accesorios a través de los productos seleccionados." />
                    <meta name="keywords" content="Comprar patines de hockey línea, comprar disco de hockey, comprar puck de hockey, comprar stick de hockey" />
                    <meta name="robots" content="index, nofollow" />
                </Helmet>
                <div>
                    <Row>
                        <Col>
                            <h1 className="products-title-label">Productos</h1>
                        </Col>
                        {this.state.isProductsPageActive && (<Col className="products-info">
                            <Button
                                id="UncontrolledPopover"
                                type="button"
                                color="link">
                                + INFO
                            </Button>
                            <UncontrolledPopover
                                placement="left"
                                target="UncontrolledPopover"
                                trigger="legacy"
                            >
                                <PopoverHeader>
                                    Programa de Afiliado
                                </PopoverHeader>
                                <PopoverBody>
                                    Los productos mostrados son vendidos y gestionados por Amazon España
                                </PopoverBody>
                            </UncontrolledPopover></Col>)}
                    </Row>
                    <h2>Selección de productos y material de hockey línea</h2>
                    {getBody()}
                </div>
                </>
            
        );
    }

    async populateProductsData() {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch(`api/products`, { headers: { "teamName": team.team } });
        const data = await response.json();
        this.setState({ products: data, loading: false });
    }
}
