import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap"
import { Loading } from "../../components/Loading"
import { ImageWithSpinner } from "../../components/ImageWithSpinner"
import { Helmet } from "react-helmet"
import fb from "../../services/firebase"
import { getLocalStorage, getGoogleMapsLink, onShare } from "../../utils"
import './places.css';

export class Places extends Component {
    constructor(props) {
        super(props);
        this.state = {
            places: [],
            loading: true,
            team: ""
        };
    }

    componentDidMount() {
        this.populatePlacesData();
    }

    static renderPlaces(places, isLoading, that) {

        const onClickPlace = (place) => {
            fb.logEvent("click_place", { name: place.teamName, team: that.state.team.name })
        }
        return (
            <Container className="places-container">
            <Row>
                {places.map(x => {
                    return (
                        <Col xs="12" md="6" xl="3" key={x.teamName}>
                            <a href={getGoogleMapsLink(x.latitude, x.longitude)} onClick={() => onClickPlace(x)} className="link-click" target="_blank" rel="noopener noreferrer">
                                <div className="places-item">
                                    <Row className="places-title">
                                        {x.teamName}
                                    </Row>
                                    <Row className="places-address">
                                        {x.name}
                                    </Row>
                                    <Row className="places-logo-container">
                                        <ImageWithSpinner
                                            className="places-team-logo"
                                            src={x.teamImageUrl}
                                            alt={`${x.teamName} logo`}
                                        />
                                    </Row>
                                    <Row className="places-address">
                                        {x.address}
                                    </Row>
                                </div>
                            </a>
                        </Col>)
                })}
                </Row>
            </Container>
        );
    }

    render() {
        let contents = Places.renderPlaces(this.state.places, this.state.loading, this);

        return (
            <>
                <Helmet>
                    <title>Ubicación de las pistas de hockey línea</title>
                    <meta name="description" content="Ubicación de las pistas de hockey línea donde se juegan los partidos. Abre google maps directamente con la ubicación de la pista seleccionada." />
                    <meta name="keywords" content="Ubicación pistas de hockey línea, pistas de hockey, lugar de juego hockey línea, hockey línea google maps" />
                    <meta name="robots" content="index, nofollow" />
                </Helmet>
                <div>
                    <Row>
                        <Col xs="9">
                            <h1>Ubicación pistas de hockey</h1>
                            <h2></h2>
                        </Col>
                        <Col xs="3" style={{ textAlign: "end", textDecoration: "none" }}>
                            <span className="share-link" onClick={() => onShare("Hockeyapp", document)}>Compartir</span>
                        </Col>
                    </Row> 
                
                    <Row>
                        {this.state.loading ? (
                            <Loading />
                        ) : <p></p>}
                    </Row>
                    {contents}
                </div>
                </>
            
        );
    }

    async populatePlacesData() {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch(`api/places`, { headers: { "teamName": team.team } });
        const data = await response.json();
        this.setState({ places: data, loading: false, team: team });
    }
}
