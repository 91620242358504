import React, { Component, useContext } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getLocalStorage } from "../utils"
import { getFireConfig } from "../services/firebase"
import { UserContext } from "../context/UserProvider";
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            isRegulationPageActive: null,
            isProductsPageActive: null,
        };
    }
    async componentDidMount() {

        await getFireConfig("isRegulationPageActive").then((res) => {
            this.setState({
                ...this.state, isRegulationPageActive: res !== "false" })
        })
        getFireConfig("isProductsPageActive").then((res) => {
            this.setState({
                ...this.state, isProductsPageActive: res !== "false" })
        })
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {

        const getLogoUrl = () => {
            const team = JSON.parse(getLocalStorage("team", "{}"));
            if (team.logoUrl) {
                return team.logoUrl;
            } else {
                return "images/hockey_logo.jpeg";
            }
        }

        const manageAuth = (user) => {
            this.setState({ collapsed: true })
            if (user) {
                const auth = getAuth();
                signOut(auth).then(function () {
                    console.log('Signed Out');
                }, function (error) {
                    console.error('Sign Out Error', error);
                });
            } else {
                window.location = "/login";
            }
        }
        return (
            <UserContext.Consumer>
                {user => (
                    <header className="header">
                        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 navbar" fixed="top">
                            <Container>
                                <NavbarBrand tag={Link} onClick={() => this.setState({ collapsed: true })} to="/"><img className="logo-navbar" src={getLogoUrl()} alt="hockey logo" /></NavbarBrand>
                                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                                    {this.state.isProductsPageActive != null && this.state.isRegulationPageActive != null && (
                                        <ul className="navbar-nav flex-grow">
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/" onClick={() => this.setState({ collapsed: true })}>EN VIVO</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/divisions" onClick={() => this.setState({ collapsed: true })}>Ligas</NavLink>
                                            </NavItem>
                                            {this.state.isRegulationPageActive && (<NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/regulation" onClick={() => this.setState({ collapsed: true })}>Reglamento</NavLink>
                                            </NavItem>)}
                                            {this.state.isProductsPageActive && (<NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/products" onClick={() => this.setState({ collapsed: true })}>Productos</NavLink>
                                            </NavItem>)}
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/places" onClick={() => this.setState({ collapsed: true })}>Ubicación pistas</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" to="/info" onClick={() => this.setState({ collapsed: true })}>Info</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-dark" onClick={() => manageAuth(user)}>{user ? "Logout" : "Login"}</NavLink>
                                            </NavItem>
                                        </ul>
                                    )}
                                </Collapse>
                            </Container>
                        </Navbar>
                    </header>
                )}
            </UserContext.Consumer>
        );
    }
}
