import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Loading } from "../../components/Loading"
import { Scorer } from "../../components/Scorer"
import { PenaltiesScorer } from "../../components/Penalties/PenaltiesScorer"
import { onShare } from "../../utils"
import { Helmet } from "react-helmet"
import fb from "../../services/firebase"
import { getTeamSelected, getLocalStorage, isWatchScreen } from '../../utils'
import { Container, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, Breadcrumb, BreadcrumbItem, Badge, UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap"
import "./Match.css"
import { RelatedMatches } from '../../components/RelatedMatches';

export class Match extends Component {
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { match: {}, loading: true, activeTab: "1" };
    }

    componentDidMount() {
        const { match } = this.props;
        const id = match.params.id;
        this.populateMatchData(id);
        try {
            this.intervalID = setInterval(async () => {
                if (!isWatchScreen()) {
                    this.setState({ ...this.state, loading: true });
                    this.populateMatchData(id);
                } else {
                    window.location.reload();
                }
            }, 15000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }


    static renderMatchData(data, that) {
        if (data?.match === undefined) return null;
        const onPlayerClick = (player) => {
            const team = getTeamSelected();
            fb.logEvent("click_player_match", {name: player.name, team: team.name})
        }

        const getBadge = (badge) => {
            let color = "warning";
            if (badge === "+PIM") {
                color = "danger";
            } else if (badge === "+%" || badge === "+G") {
                color = "success";
            }
            return (<Badge pill color={color}>{badge}</Badge>);
        }

        const getStatsTable = (isTeam1) => {
            const dataSource = isTeam1 ? data?.team1 : data?.team2;
            return (<>
            <Row className="stats-team-row"><Col>{isTeam1 ? data?.match.name1 : data?.match.name2}</Col><Col><img className="result-team-logo" src={isTeam1 ? data?.match.logo1 : data?.match.logo2}/></Col></Row>
                <Row className="stats-header-row">
                    <Col xs="1" style={{textAlign:'right'}}>N</Col>
                    <Col xs="1">P</Col>
                    <Col xs="5">Nombre</Col>
                    <Col xs="1" style={{textAlign:'right'}}>PT</Col>
                    <Col xs="1" style={{textAlign:'right'}}>G</Col>
                    <Col xs="1" style={{textAlign:'right'}}>A</Col>
                    <Col xs="2" style={{textAlign:'right'}}>PIM</Col>
                </Row>
                {dataSource.filter(x => x.position !== "P").map((playerStat, i) => {

                    const rowClassName = i % 2 == 0 ? "row-odd" : "row-even"
                    return (
                        <>
                            <Row className={rowClassName} key={`${playerStat.number}-${i}`}>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.number}</Col>
                                <Col xs="1">{playerStat.position}</Col>
                                <Col xs="5"><Link to={`/player/${playerStat.playerId}?isRFEP=${data?.isRFEP}`} className="player-link-click" onClick={() => onPlayerClick(playerStat)}>{playerStat.name}</Link> {playerStat.badge.length > 0 && playerStat.badge.map(x => getBadge(x))}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.shoots}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.goals}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.assistances}</Col>
                                <Col xs="2" style={{textAlign:'right'}}>{playerStat.fouls}</Col>
                            </Row>
                        </>
                    )
                })}
                <Row className="stats-header-row">
                    <Col xs="1" style={{textAlign:'right'}}>N</Col>
                    <Col xs="1">P</Col>
                    <Col xs="5">Nombre</Col>
                    <Col xs="1" style={{textAlign:'right'}}>GA</Col>
                    <Col xs="1" style={{textAlign:'right'}}>TR</Col>
                    <Col xs="1" style={{textAlign:'right'}}>%</Col>
                    <Col xs="2" style={{ textAlign: 'right' }}>PIM</Col>
                </Row>
                {dataSource.filter(x => x.position === "P").map((playerStat, i) => {
                    const rowClassName = i % 2 == 0 ? "row-odd" : "row-even"
                    return (
                        <>
                            <Row className={rowClassName} key={`${playerStat.number}-${i}`}>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.number}</Col>
                                <Col xs="1">{playerStat.position}</Col>
                                <Col xs="5"><Link to={`/player/${playerStat.playerId}?isRFEP=${data?.isRFEP}`} className="player-link-click" onClick={() => onPlayerClick(playerStat)}>{playerStat.name}</Link> {playerStat.badge.length > 0 && playerStat.badge.map(x => getBadge(x))}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.goals}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{playerStat.shoots}</Col>
                                <Col xs="1" style={{textAlign:'right'}}>{(playerStat.catchesAvg * 100).toFixed(2)}</Col>
                                <Col xs="2" style={{ textAlign: 'right' }}>{playerStat.fouls}</Col>
                            </Row>
                        </>
                    )
                })}</>)
        }

        const getActionBadge = (action, key) => {
            if (action.emptyNet) {
                return (<Badge pill color="secondary">{action.emptyNet ? "Porteria Vacia" : action.faultType}</Badge>);
            } else if (action.faultType != null) {
                return (<>
                    <Badge pill color="danger" id={`UncontrolledPopover-${key}`}> {action.faultType}</Badge>
                    <UncontrolledPopover
                        placement="top"
                        target={`UncontrolledPopover-${key}`}
                        trigger="legacy"
                    >
                        <PopoverHeader>
                            {action.faultType}
                        </PopoverHeader>
                        <PopoverBody>
                            {action.faultDescription}
                        </PopoverBody>
                    </UncontrolledPopover>
                </>);
            }      
        }

        return (<>
            <Container className="match-container-scorer">
                <Scorer match={data.match} hideTitle={true} />
                <Row className={`${data.match.status.toLowerCase() === "finalizado" ? "finished" : ""} ${isWatchScreen() ? "result-status-team-row-watch" : "result-status-team-row"}`} key={`${data.match.name1}-${data.match.name1}-${data.match.status}-second`}>
                    <Col xs="12" md="12">{data.match.status}</Col>
                </Row>
            </Container>
            {!isWatchScreen() && (
            <>
            <Container>
                {data.match.penalties !== null && <Row style={{ marginBottom: "30px" }}><PenaltiesScorer match={data.match} /> </Row>}
                {data.relatedMatches.length > 0 && <Row style={{ marginBottom: "30px", justifyContent: "center" }}><RelatedMatches relatedMatches={data.relatedMatches} /> </Row>}
            </Container>
            <Nav pills fill>
                <NavItem>
                    <NavLink
                        className={that.state.activeTab === "1" ? "active" : ""}
                        onClick={() => that.setState({ ...that.state, activeTab: "1" })}
                    >
                        Ficha
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={that.state.activeTab === "2" ? "active" : ""}
                        onClick={() => that.setState({ ...that.state, activeTab: "2" })}
                    >
                        Incidencias
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={that.state.activeTab}>
                <TabPane tabId="1">
                        <Container className="stats-container">
                            {getStatsTable(true)}
                        </Container>
                    <Container className="stats-container">
                        {getStatsTable(false)}
                    </Container>
                </TabPane>
                <TabPane tabId="2">
                    <Container className="actions-container">
                        {data?.actions?.map((action, i) => {   
                            let rowClassName = i % 2 == 0 ? "row-odd" : "row-even"
                            rowClassName += " action-row";
                            return (<Row className={rowClassName} key={`${action.half}-${action.minute}-${i}`}>
                                <Col xs="1">
                                    <Row>{action.half}</Row>
                                    <Row>{action.minute}</Row>
                                </Col>
                                <Col xs="2" style={{textAlign:'center'}}>
                                    <div>{action.iconUrl?.length > 0 && (<img src={action.iconUrl} width="25px" height="25px" />)}</div>
                                    <Row>{action.playerNumber && action.playerNumber}</Row>
                                </Col>
                                <Col xs="2" style={{textAlign:'center'}}>
                                    <div>{action.teamLogo?.length > 0 && (<img src={action.teamLogo} width="35px" height="35px" />)}</div>
                                    <Row>{action.teamName}</Row>
                                    <Row>{action.result}</Row>
                                </Col>
                                <Col xs="7">{getActionBadge(action, i)} {action.actionText}</Col>
                            </Row>)
                        }
                        )}
                    </Container>
                </TabPane>
            </TabContent></>)}
        </>
        );
    }

    render() {
        const { match } = this.state;
        let contents = Match.renderMatchData(match, this);

        return (
            <>
                {match.match && (<Helmet>
                    <title>{`Partido hockey línea ${match.match.name1} - ${match.match.name2}`}</title>
                    <meta name="description" content={`Informacion del partido de hockey línea que enfrenta a ${match.match.name1} contra ${match.match.name2}`} />
                    <meta name="keywords" content={`resultado hockey linea, ${match.match.name1} contra ${match.match.name2}`} />
                    <meta name="robots" content="index, follow" />
                </Helmet>)}
            <div style={{ width: "100%", marginBottom: "30px" }}>
                <Row>
                    <Col>
                        <Breadcrumb>
                            <BreadcrumbItem active onClick={() => this.props.history.goBack()}>Atrás</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>
                    {match.match?.urlVideo && (
                        <Col style={{ textAlign: "end" }}>
                            <Link to={{ pathname: match.match?.urlVideo }} target="_blank" style={{ textDecoration: "none" }}>
                                Ver partido
                            </Link>
                        </Col>
                    )}
                    <Col style={{ textAlign: "end", textDecoration: "none" }}>
                        <span className="share-link" onClick={() => onShare("Hockeyapp", document)}>Compartir</span>
                    </Col>
                </Row> 
                <p style={{ width: "100%" }}>{this.state.loading ? (
                    <Loading />
                    ) : match.match?.divisionId ? (<Link to={{ pathname: "/division/" +  match.match.divisionId  }} style={{ textDecoration: "none" }}>
                            {this.state.match.match.category}
                        </Link>) : this.state.match.match.category }
                </p>
                {contents}
                </div>
            </>
        );
    }

    async populateMatchData(id) {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch('api/hockey/match/' + id, { headers: { "teamName": team.team } });
        const data = await response.json();
        this.setState({ match: data, loading: false });
    }
}
