import React, { Component } from 'react';
import { Loading } from "../../components/Loading"
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody, Row, Container } from "reactstrap"
import { getLocalStorage, setLocalStorage, scrollToJustAbove } from "../../utils"
import { PlayerStatsTable } from "../../components/PlayerStats/PlayerStatsTable"
import { Helmet } from "react-helmet"
import "./PlayerStats.css"

export class PlayerStats extends Component {
    static displayName = PlayerStats.name;
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { playerStats: [], loading: true };
    }

    componentDidMount() {
        this.populatePlayerStatsData();
        try {
            this.intervalID = setInterval(async () => {
                this.setState({ ...this.state, loading: true });
                this.populatePlayerStatsData();
            }, 60000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }


    static renderPlayerStatsTable(divisions, isLoading) {

        let lastOpenAccordion = parseInt(getLocalStorage("playerstats-accordion", 0));
        return (<>
            <UncontrolledAccordion
            defaultOpen={[
                lastOpenAccordion
            ]}
        >
        {
            divisions.map((division, index) => {
                let accordionId = index + 1;
                return (
                    <AccordionItem onClick={() => setLocalStorage("playerstats-accordion", accordionId)}>
                        <AccordionHeader targetId={accordionId} className={`accordion-${accordionId}`}>
                            {division.name}
                        </AccordionHeader>
                        <AccordionBody accordionId={accordionId}>
                            <PlayerStatsTable playerStats={division.playerStats} isRFEP={division.isRfep } />
                        </AccordionBody>
                    </AccordionItem>)
            })
        }
            
        </UncontrolledAccordion>
         {
              !isLoading && divisions.length === 0 && (
                <Container className="noresult-matches-container">
                    <img src="images/wolf_player.jpg" className='noresults-image' />
                    <Row xs="12">
                        <p>No se han podido descargar los datos</p>
                    </Row>
                </Container>)
        }</>
        )
    }

render() {
    let contents = PlayerStats.renderPlayerStatsTable(this.state.playerStats, this.state.loading);
    setTimeout(() => {
        let lastOpenAccordion = parseInt(getLocalStorage("playerstats-accordion", 0));
        let accordionClass = `.accordion-${lastOpenAccordion}`;
        scrollToJustAbove(document.querySelector(accordionClass))
    }, 500)
    return (
        <>
            <Helmet>
                <title>Player</title>
                <meta name="description" content="Información del jugador seleccionado" />
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
        
            <div className='container-classification'>
                <h1>Plantillas</h1>
                {this.state.loading ? (
                    <Loading />
                ) : <p>&nbsp;</p>}
                {contents}
                </div>
        </>
    );
}

async populatePlayerStatsData() {
    const response = await fetch('api/division/playerstats');
    const data = await response.json();
    this.setState({ playerStats: data, loading: false });
    }
}
