import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Classification } from './pages/Classification/Classification';
import { Calendar } from './pages/Calendar/Calendar';
import { Home } from './pages/Home/Home';
import { Results } from './pages/Results/Results';
import { Match } from './pages/Match/Match';
import { Player } from './pages/Player/Player';
import { PlayerStats } from './pages/PlayerStats/PlayerStats';
import { Division } from './pages/Divisions/Division';
import { Divisions } from './pages/Divisions/Divisions';
import { Teams } from './pages/Teams/Teams';
import { Places } from './pages/Places/Places';
import { Products } from './pages/Products/Products';
import { Regulation } from './pages/Regulation/Regulation';
import { Game } from './pages/Game/Game';
import { Rules } from './pages/Game/Rules';
import { Login } from "./pages/Auth/Login"
import { SignUp } from "./pages/Auth/SignUp"

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {

        return (
            <Layout>
                <Route exact path='/' component={Results} />

                <Route path="/login" component={Login} />
                <Route path="/signup" component={SignUp} />
                <Route path='/playerstats' component={PlayerStats} />
                <Route path='/calendars' component={Calendar} />
                <Route path='/classification/' component={Classification} />
                <Route path='/info' component={Home} />
                <Route path='/player/:id' component={Player} />
                <Route path='/match/:id' component={Match} />
                <Route path='/division/:id' component={Division} />
                <Route exact path='/game/docs/rules' component={Rules} />
                <Route exact path='/game/:id' component={Game} />
                <Route path='/divisions' component={Divisions} />
                <Route path='/teams' component={Teams} />
                <Route path='/products' component={Products} />
                <Route path='/places' component={Places} />
                <Route path='/regulation' component={Regulation} />
            </Layout>
        );
    }
}
