import React, { Component } from 'react';
import { Container, Row, Col } from "reactstrap"
import { Link } from 'react-router-dom';
import { Loading } from "../../components/Loading"
import { Scorer } from "../../components/Scorer"
import { getLocalStorage, setLocalStorage, isWatchScreen } from "../../utils"
import { Helmet } from "react-helmet"
import './Results.css';

export class Results extends Component {
    static intervalID;
    constructor(props) {
        super(props);
        this.state = {
            matches: [], loading: true, team: {} };
    }

    componentDidMount() {
        this.populateMatchesData();
        try {
            this.intervalID = setInterval(async () => {
                this.setState({ ...this.state, loading: true });
                this.populateMatchesData();
            }, 15000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }


    static renderMatchesTable(matches, isLoading, team) {
        const getMatchData = (match, index) => {
            return (
                <Scorer match={match} key={index} />
            )
        }
        return (<>
            <Container className="result-matches-container">
                {matches.map((match, i) => {
                    const matchLink = match.id ? `/match/${match.id}` : null;
                    return getMatchData({ ...match, matchLink }, i);
                })}
            </Container>
            {!isLoading && matches.length === 0 && (
                <Container className="noresult-matches-container">
                    <img src={team.logoUrl} alt={`${team.name} logo`} className='noresults-image' />
                    <Row xs="12">
                        <p>No hay partidos para mostrar</p>
                    </Row>
                </Container>)}
        </>
        );
    }

    render() {
        let contents = Results.renderMatchesTable(this.state.matches, this.state.loading, this.state.team);

        return (
            <>
                 <Helmet>
                    <title>Resultados hockey línea Comunidad Valenciana</title>
                    <meta name="description" content="Resultados y clasificación de Hockey línea de los equipos de la provincia de Castellón. Selecciona tu equipo preferido de entre los siguientes: llops vila-real, taurons nules, dinos borriol, orcas oropesa, hockey línea castellon" />
                    <meta name="robots" content="index, follow" />
                    <meta name="keywords" content="hockey, hockey linea, resultados hockey línea, roller hockey, llops vila-real, taurons nules, dinos borriol, orcas oropesa, hockey línea castellon" />
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Ligas",
                                "item": "https://hockeylinea.online/divisions",
                                "description": "Clasficación ligas y torneos de hockey línea de los equipos de la provincia de Castellón"
                            }, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "Reglamento",
                                "item": "https://hockeylinea.online/regulation",
                                "description": "Realiza preguntas sobre el reglamento del hockey línea o descárgalo en un documento PDF."
                            }, {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "Productos",
                                "item": "https://hockeylinea.online/products",
                                "description": "Comprar patines de hockey línea, comprar disco de hockey, comprar puck de hockey, comprar stick de hockey"
                            }, {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "Ubicación pistas",
                                "item": "https://hockeylinea.online/places",
                                "description": "Ubicación de las pistas de hockey línea donde se juegan los partidos"
                            }]
                        })}
                    </script>
                </Helmet>
                <div>
                    <Row className={isWatchScreen() ? "division-container-header-watch" : "division-container-header"}>
                        <Row>
                            <Col xs="9">
                                <h1 id="tabelLabel">Resultados Hockey</h1>
                            </Col>
                            <Col xs="3" className="result-choose-team ">
                                <Link to={{ pathname: "/teams" }} style={{ textDecoration: "none" }} onClick={() => setLocalStorage("changeTeamFrom", "results")}>
                                    Elige tu equipo
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            {this.state.loading ? (
                                <Loading />
                            ) : <h2>{this.state.team?.name ?? "Llops Vila-real"}</h2>}
                        </Row>
                    </Row>
                    {contents}
                </div>
            </>
        );
    }

    async populateMatchesData() {
        const team = JSON.parse(getLocalStorage("team", "{}"));
        const response = await fetch('api/hockey/matches', { headers: {"teamName": team.team}});
        const data = await response.json();
        this.setState({ matches: data, loading: false , team: team});
    }
}
