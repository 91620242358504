import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from "reactstrap"
import { Loading } from "../../components/Loading"
import { getTeamSelected } from "../../utils"
import { Helmet } from "react-helmet"
import { MessageList, Input, Button } from "react-chat-elements"
import { sendMessage } from "../../services/chatpdf"
import { getFireConfig } from "../../services/firebase"
import fb from "../../services/firebase"

import './Regulation.css';
import "react-chat-elements/dist/main.css"

const titleBot = "LlopBot";
const titleUser = "Usuario";

export function Regulation() {

    const team = getTeamSelected();

    const [isRegulationPageActive, setIsRegulationPageActive] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ chat, setChat ] = useState([]);
    const listRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
            setChat([
                {
                    position: "left",
                    type: "text",
                    title: titleBot,
                    text: "Pregúntame por tu duda y la buscaré en el reglamento.",
                }
            ]);
        }, 800)

        getFireConfig("isRegulationPageActive").then((res) => {
            setIsRegulationPageActive(res !== "false")
        })

        // Avoid scroll body in iOS
        const body = document.querySelector('body');
        body.style.overflow = 'hidden';
        return () => {
            body.style.removeProperty('overflow');
        }

    }, [])
    const onClickSend = () => {
        const inputText = inputRef.current.value;

        if (inputText.length > 2) {
            const updateChat = [...chat, {
                position: "right",
                type: "text",
                title: titleUser,
                text: inputText,
            }]

            setLoading(true);
            setChat(updateChat);

            fb.logEvent("click_sendChatPDFMessage", { team: team.name })
            sendMessage(updateChat).then((response) => {

                setLoading(false);
                setChat([...updateChat, {
                    position: "left",
                    type: "text",
                    title: titleBot,
                    text: response.content,
                }]);

                listRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
                inputRef.current.value = "";
            });
        } else {
            const updateChat = [...chat, {
                position: "left",
                type: "text",
                title: "",
                text: "Escribe algo más largo, por favor ;-)",
            }]

            setChat(updateChat);
        }
    }

    return (
        <div>
            <Helmet>
                <title>Reglamento hockey línea - Inteligencia artificial</title>
                <meta name="description" content="Realiza preguntas sobre el reglamento del hockey línea o descárgalo en un documento PDF. La respuesta es generada por inteligencia artificial entrenada específicamente para este propósito." />
                <meta name="keywords" content="Reglamento hockey línea, pregunta reglamento hockey línea, descarga reglamento hockey línea, duda reglamento hockey línea, normas hockey línea" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="regulation-main-container">
                <Row>
                    <Col xs="9"><h1 id="tabelLabel">Reglamento Hockey Línea</h1></Col>
                    <Col xs="3" className="regulation-download">
                        <a href="/files/2024_04_09 Reglas de juego 2024.pdf" className="nav-link" onClick={()=> fb.logEvent("click_downloadRegulation", { team: team.name }) } target="_blank">Descarga el reglamento</a>
                    </Col>
                </Row>
                <h2>{loading && isRegulationPageActive ? (
                    <Loading />
                ) : ""}</h2>
                {isRegulationPageActive ? (<Container className="regulation-container">
                    <MessageList
                        className='message-list'
                        lockable={true}
                        toBottomHeight={'100%'}
                        dataSource={chat}
                        referance={listRef}
                    />
                    <Input
                        referance={inputRef}
                        placeholder={!loading ? "Escribe aquí tu pregunta..." : "Cargando..."}
                        multiline={true}
                        className="regulation-input"
                        rightButtons={<Button text={"Enviar"} backgroundColor="#8c98c1" onClick={onClickSend} title="Enviar" />}
                    />
                </Container>) : isRegulationPageActive === false ? (<p>Lo sentimos, el servicio no está disponible en estos momentos</p>) : ""}                
            </div>
        </div>
    );
}
