import React, { Component } from 'react';
import "./Classification.css"
import { Loading } from "../../components/Loading"
import { UncontrolledAccordion, AccordionItem, AccordionHeader, AccordionBody } from "reactstrap"
import { getLocalStorage, setLocalStorage, scrollToJustAbove } from "../../utils"
import { ClassificationTable } from '../../components/Classification/ClassificationTable';

export class Classification extends Component {
    static displayName = Classification.name;
    static intervalID;
    constructor(props) {
        super(props);
        this.state = { divisions: [], loading: true };
    }

    componentDidMount() {
        this.populateClassificationData();
        try {
            this.intervalID = setInterval(async () => {
                this.setState({ ...this.state, loading: true });
                this.populateClassificationData();
            }, 60000);
        } catch (e) {
            console.log(e);
        }
    }

    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }


    static renderClassificationTable(divisions) {
        console.log(divisions)
        let lastOpenAccordion = parseInt(getLocalStorage("classification-accordion", 0));
        return (<UncontrolledAccordion
            defaultOpen={[
                lastOpenAccordion
            ]}
          >
            {divisions.map((division, index) => {
                let accordionId = index + 1;
                console.log(division)
                return (
                    <AccordionItem onClick={() => setLocalStorage("classification-accordion", accordionId)}>
                        <AccordionHeader targetId={accordionId} className={`accordion-${accordionId}`}>
                      {division.name}
                    </AccordionHeader>
                        <AccordionBody accordionId={accordionId}>
                            {division.classificationList.map((div, divIndex) => {
                                return <ClassificationTable division={div} index={divIndex} isRfep={division.isRfep} />
                     })}
                    </AccordionBody>
                  </AccordionItem>)
            })}
            
          </UncontrolledAccordion>)
    }

    render() {
        let contents = Classification.renderClassificationTable(this.state.divisions);
        setTimeout(() => {
            let lastOpenAccordion = parseInt(getLocalStorage("classification-accordion", 0));
            let accordionClass = `.accordion-${lastOpenAccordion}`;
            scrollToJustAbove(document.querySelector(accordionClass))
        }, 500)
        return (
            <div className='container-classification'>
                <h1>Clasificación</h1>
                {this.state.loading ? (
                    <Loading />
                ) : <p>&nbsp;</p>}
                {contents}
            </div>
        );
    }

    async populateClassificationData() {
        const response = await fetch('api/division/classification');
        const data = await response.json();
        console.log(data)
        this.setState({ divisions: data, loading: false });
    }
}
