import React from 'react';
import { useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet"
import './Rules.css'
import { Container, List, Col, Row, Breadcrumb, BreadcrumbItem } from "reactstrap"

export function Rules() {
    let history = useHistory();
    return (<>
        <Helmet>
            <title>Juego de Hockey línea Comunidad Valenciana</title>
            <meta name="description" content="Crea tu línea de juego por categoría y equipo y gana puntos en función de los resultados reales de cada partido. Cambia tu línea después de cada partido para hacerla más competitiva." />
            <meta name="keywords" content="liga fantasy, hockey linea fantasy, juego hockey, juego real hockey" />
            <meta name="robots" content="index, follow" />
        </Helmet>    
        <Row>
            <Col xs="9">
                <Breadcrumb>
                    <BreadcrumbItem active onClick={() => history.goBack()}>Atrás</BreadcrumbItem>
                </Breadcrumb>
            </Col>
        </Row>
        <Container className="rules">
            <h1>Reglas de juego</h1>
            <h2>Introducción</h2>
            <p>Al entrar por primera vez en el juego, deberás añadir tu nombre que es el que se utilizará para mostrar en el Ranking.
                Puedes usar tu nombre real o un el apodo que prefieras.</p>
            <p>Seguidamente podrás seleccionar los jugadores para tu línea en la categoría seleccionada. Puede tener 1 línea por cada categoría por cada equipo.
                Los Rankings también se realizarán a ese nivel. Por lo tanto puedes tener, por ejemplo, una línea con el equipo Llops de Benjamín y otra con el equipo Taurons de Benjamín.</p>
            <p>No se podrá cambiar los jugadores de la línea durante las 6 horas previas al partido, pero si podrá hacerse una vez finalizado éste, para el próximo partido.</p>
            <h2>Puntuación</h2>
            <p>Se contarán los puntos generados por los jugadores de tu línea una vez finalizado el partido, teniendo en cuenta los siguientes criterios:</p>
            <List>
                <li>Se sumará 1 punto por cada gol o asistencia.</li>
                <li>Se restará 1 punto por cada minuto de expulsión.</li>
                <li>Si el jugador es portero, se le asignarán los puntos en función del porcentaje de paradas:</li>
                <ul>
                    <li>1 punto cuando el porcentaje sea superior o igual a 70 e inferior a 80.</li>
                    <li>2 puntos cuando el porcentaje sea superior o igual a 80 e inferior a 90.</li>
                    <li>3 puntos cuando el porcentaje sea superior o igual a 90.</li>
                </ul>
            </List>
        </Container>
    </>
    );
}
